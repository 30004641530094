import React from 'react';
import './App.css';

const imgPath = './images/';
let imgName = '';

function setImage( season ) {
  imgName = imgPath + 'test-' + season + '.jpg';
  return true;
}

function getImage() {
  return (
    <li><img src={imgName} alt="" /></li>
  );
}

function App() {
  return (
    <React.Fragment>
      <header>
        <h1>アフェティリア写真集</h1>
      </header>
      <div className="album-wrap">
        <ul className='album-picture'>
        {getImage}
        </ul>
        <p>
          <button onClick={() => setImage( 'spring' )}>春</button>
          <button onClick={() => setImage( 'summer' )}>夏</button>
          <button onClick={() => setImage( 'autumn' )}>秋</button>
          <button onClick={() => setImage( 'winter' )}>冬</button>
        </p>
      </div>
      <footer>
        <p>Copyright c 2023 Afetiria All Rights Reserved.</p>
      </footer>
    </React.Fragment>
  );
}

export default App;
